import React from "react";
import logo from "../../Assets/login/Logo.svg";
const ForgetPassword = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white w-[90%] md:w-[500px] px-14 py-10 rounded-lg shadow-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center">
          <img src={logo} alt="logo" />
        </div>

        <div className="text-center">
          <h2 className="text-[34px] font-semibold">Forgot Password</h2>
        </div>

        <div className="text-[#6C6C6C] mt-4 text-center">
          Enter your email and we'll send you instructions to reset your
          password
        </div>
        <form className="mt-5 flex flex-col gap-4">
          <input
            type="email"
            placeholder=" Email"
            className="w-full h-[50px] border border-gray-300 rounded-lg px-4 outline-none"
          />
          <button
            type="submit"
            className="w-full bg-[#6699FF] text-white py-2 rounded-lg font-semibold"
          >
            Send
          </button>
        </form>

        <div className="text-center mt-4">
          <button onClick={onClose} className="text-[#6D788D] font-semibold">
            Back To Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
