// import React from "react";
// import search from "../../Assets/Navbar/search.svg";
// import message from "../../Assets/Navbar/message.svg";
// import notification from "../../Assets/Navbar/notification.svg";
// import avatar from "../../Assets/Navbar/Avatar.png";
// import arrowdown from "../../Assets/Navbar/arrow-down.svg";

// const Navbar = () => {
//   return (
//     <div className="flex flex-wrap justify-between items-center w-full p-4 border-b border-[#EEEEEE]">
//       <div className="w-full sm:w-auto flex items-center mb-3 sm:mb-0">
//         <div className="flex items-center p-2 rounded-md w-full sm:w-auto max-w-md">
//           <img src={search} alt="icon" className="w-5 h-5 text-gray-500 mr-2" />
//           <input
//             type="text"
//             placeholder="What are you looking for...?"
//             className="w-full sm:w-[750px] bg-transparent outline-none placeholder-gray-600 text-sm"
//           />
//         </div>
//       </div>

//       <div className="flex items-center gap-x-4 sm:gap-x-5">
//         <div>
//           <img src={message} alt="Message Icon" className="w-8 h-8 sm:w-auto" />
//         </div>

//         <div>
//           <img
//             src={notification}
//             alt="Notification Icon"
//             className="w-8 h-8 sm:w-auto"
//           />
//         </div>

//         <div className="flex items-center border-r border-l border-[#EAEAEC] px-3 py-1">
//           <div className="relative w-[30px] sm:w-[40px] h-[30px] sm:h-[40px]">
//             <img
//               src={avatar}
//               alt="Avatar"
//               className="rounded-full w-full h-full"
//             />
//             <span className="absolute bottom-0 right-0 w-2 h-2 sm:w-2.5 sm:h-2.5 bg-green-500 border-2 border-white rounded-full"></span>
//           </div>
//           <div className="hidden sm:flex flex-col ml-2">
//             <span className="font-semibold text-[12px] sm:text-[14px]">
//               Firdaus Nur Wachid
//             </span>
//             <span className="text-[#6C6C6C] text-[12px] sm:text-[14px]">
//               @firdausnurwachid_
//             </span>
//           </div>
//         </div>

//         <div className="text-[#6C6C6C] text-[14px] sm:text-[16px] flex items-center cursor-pointer">
//           EN
//           <img src={arrowdown} alt="Dropdown Arrow" className="ml-1" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;

// Updated Navbar Component
import React from "react";
import search from "../../Assets/Navbar/search.svg";
import message from "../../Assets/Navbar/message.svg";
import notification from "../../Assets/Navbar/notification.svg";
import avatar from "../../Assets/Navbar/Avatar.png";
import arrowdown from "../../Assets/Navbar/arrow-down.svg";

const Navbar = () => {
  return (
    <div className="flex flex-wrap justify-between items-center w-full p-4 border-b border-[#EEEEEE]">
      {/* Mobile Hamburger Icon */}
      <div className="flex items-center sm:hidden">
        <button className="p-2">
          <span className="block w-5 h-0.5 bg-gray-600 mb-1"></span>
          <span className="block w-5 h-0.5 bg-gray-600 mb-1"></span>
          <span className="block w-5 h-0.5 bg-gray-600"></span>
        </button>
      </div>

      {/* Search Bar */}
      <div className="hidden sm:flex w-full sm:w-auto items-center mb-3 sm:mb-0">
        <div className="flex items-center p-2 rounded-md w-full sm:w-auto max-w-md">
          <img src={search} alt="icon" className="w-5 h-5 text-gray-500 mr-2" />
          <input
            type="text"
            placeholder="What are you looking for...?"
            className="w-full sm:w-[750px] bg-transparent outline-none placeholder-gray-600 text-sm"
          />
        </div>
      </div>

      {/* Icons and Profile */}
      <div className="flex items-center gap-x-4 sm:gap-x-5">
        {/* Message Icon */}
        <img
          src={message}
          alt="Message Icon"
          className="w-6 h-6 sm:w-8 sm:h-8"
        />

        {/* Notification Icon */}
        <img
          src={notification}
          alt="Notification Icon"
          className="w-6 h-6 sm:w-8 sm:h-8"
        />

        {/* Profile Section */}
        <div className="flex items-center lg:border-r lg:border-l border-[#EAEAEC] px-3 py-1">
          <div className="relative w-8 h-8 sm:w-10 sm:h-10">
            <img
              src={avatar}
              alt="Avatar"
              className="rounded-full w-full h-full"
            />
            <span className="absolute bottom-0 right-0 w-2 h-2 sm:w-2.5 sm:h-2.5 bg-green-500 border-2 border-white rounded-full"></span>
          </div>
          <div className="hidden sm:flex flex-col ml-2">
            <span className="font-semibold text-[12px] sm:text-[14px]">
              Firdaus Nur Wachid
            </span>
            <span className="text-[#6C6C6C] text-[12px] sm:text-[14px]">
              @firdausnurwachid_
            </span>
          </div>
        </div>

        {/* Language Selector */}
        <div className="hidden sm:flex text-[#6C6C6C] text-[14px] sm:text-[16px] items-center cursor-pointer">
          EN
          <img src={arrowdown} alt="Dropdown Arrow" className="ml-1" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
