import React from "react";
import reportproblem from "../../Assets/Home/report-problem.svg";
import { Plus } from "lucide-react";
import TotalPoints from "./TotalPoints";
import ActivityFeed from "./ActivityFeed";
import HomeBlogs from "./HomeBlogs";
import NoActiveChallenges from "./NoActive";
const Home = () => {
  return (
    <div>
      <div className="flex lg:justify-end lg:flex-row  flex-col-reverse lg:gap-x-3 gap-y-3">
        <div className="bg-[#f9f4f0] text-[#FDB528] lg:text-[14px] text-[12px] lg:w-[570px] w-full h-[44px] rounded-lg flex items-center justify-center gap-x-2">
          <img src={reportproblem} alt="" />
          Welcome to Propmodel! To get started, click on the start challenge
          button.
        </div>
        <div className="font-semibold bg-[#6699FF] text-white lg:w-[217px] h-[44px] rounded-lg flex justify-center items-center gap-x-2">
          <Plus />
          Start a challenge
        </div>
      </div>
      <div
        style={{
          background:
            "linear-gradient(96.85deg, #011C57 -12.2%, #009AD8 26.99%, #001456 35.18%, #006BB2 67.53%, #010187 81.05%, #0000A0 122.94%)",
        }}
        className="h-[120px] rounded-xl bg-blue-200 mt-5 text-white lg:text-[34px] text-[15px] flex justify-center items-center"
      >
        START YOUR #PROPMODEL JOURNEY
      </div>

      <TotalPoints />
      {/* <NoActiveChallenges /> */}
      <ActivityFeed />
      <HomeBlogs />
    </div>
  );
};

export default Home;
