import React from "react";
import balance from "../../../Assets/Newchallenge/balance.svg";
import wallet from "../../../Assets/Newchallenge/wallet.svg";
import profile from "../../../Assets/Newchallenge/profile.svg";
import setting from "../../../Assets/Newchallenge/setting.svg";
const Balance = () => {
  return (
    <div className="bg-white rounded-2xl border-[#EAEAEC] border flex justify-between lg:flex-row flex-col p-5">
      <div className="w-full sm:w-[270px] flex flex-col justify-between">
        <div className="bg-[#F5F5F5] rounded-lg w-full h-[42px] flex items-center justify-between gap-x-2">
          <div className="text-[#6C6C6C] font-semibold w-[97px] h-[30px] flex justify-center items-center">
            Drawdown
          </div>
          <div className="bg-white rounded-lg text-[#6699FF] font-semibold w-[79px] h-[30px] flex justify-center items-center">
            Balance
          </div>
          <div className="text-[#6C6C6C] font-semibold w-[69px] h-[30px] flex justify-center items-center">
            Equity
          </div>
        </div>

        <div className="flex flex-col gap-y-2 mt-4">
          <div className="bg-[#FAFCFD] w-[60px] h-[60px] rounded-full border-[#EEEEEE] border flex justify-center items-center mx-auto sm:mx-0">
            <img src={wallet} alt="Wallet Icon" />
          </div>
          <div className="text-[16px] font-semibold text-center sm:text-left">
            Total Balance
          </div>
          <div className="text-center sm:text-left">
            <div className="text-[34px] font-semibold">$80,500</div>
            <div className="text-[#6C6C6C] text-[12px]">
              Recorded balance for a year
            </div>
          </div>
        </div>

        <div className="flex justify-center sm:justify-start gap-x-2 mt-4">
          <img
            src={profile}
            alt="Profile Icon"
            className="w-[24px] h-[24px] cursor-pointer"
          />
          <img
            src={setting}
            alt="Setting Icon"
            className="w-[24px] h-[24px] cursor-pointer"
          />
        </div>
      </div>

      <div className="border-l border-[#EAEAEC] pl-10">
        <img src={balance} alt="" className="" />
      </div>
    </div>
  );
};

export default Balance;
