import React from "react";
import points from "../../Assets/Home/totalpoints.svg";
import pointsgraph from "../../Assets/Home/pointsgraph.svg";
import medalstar from "../../Assets/Home/medal-star.svg";
const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full sm:w-[144px] bg-[#EDF4FF] rounded-full h-2 mt-2">
      <div
        className="bg-[#6699FF] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const TotalPoints = () => {
  return (
    <div className="mt-3 flex lg:flex-row flex-col lg:gap-x-4 gap-y-4 ">
      <div className="w-full rounded-2xl bg-white flex lg:justify-between lg:flex-row lg:gap-0 gap-y-5 flex-col p-5 ">
        <div>
          <div className="w-[60px] h-[60px] bg-[#FAFCFD] rounded-full border-[#EEEEEE] border flex justify-center items-center">
            <img src={points} alt="" />
          </div>
          <div>
            <div className="font-semibold">Total Point</div>
            <div className="text-[34px] font-semibold">80,500</div>
          </div>
          <div className="text-[#6C6C6C] text-[14px] font-normal">
            Recorded Point for a week
          </div>
        </div>

        <div className="lg:border-l   lg:border-[#EAEAEC] lg:pl-5 ">
          <img src={pointsgraph} alt="" />
        </div>
        <div className="w-[171px] flex flex-col gap-y-5">
          <div className="flex flex-col gap-y-2">
            <div className="font-semibold">Goal A</div>
            <BlueProgressBar progress={20} />
            <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between">
              <div>20,500 Point</div>

              <div>20%</div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="font-semibold">Goal B</div>
            <BlueProgressBar progress={40} />
            <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between">
              <div>60,500 Point</div>

              <div>75%</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#6699FF]  rounded-2xl flex flex-col justify-center items-center lg:gap-y-5 py-5 px-6 gap-y-5">
        <div className="text-[#65D31D] bg-[#F4FDEE] rounded-2xl w-[104px] h-[28px] flex justify-center items-center font-medium text-[12px] ">
          Next challenge
        </div>
        <div className="flex items-center text-white">
          <img src={medalstar} alt="" />
          <div className="font-semibold text-[34px]">200pt</div>
        </div>
        <div className="text-white text-[16px] font-semibold">
          Challenge A-Z
        </div>
        <button className="text-[#6699FF] bg-white h-[38px] rounded-lg w-[225px] font-semibold">
          Start a challenge
        </button>
      </div>
    </div>
  );
};

export default TotalPoints;
