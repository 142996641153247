import React from "react";
import greystar from "../../Assets/Competitions/greystar.svg";
import orangestar from "../../Assets/Competitions/orangestar.svg";
import bluestar from "../../Assets/Competitions/bluestar.svg";
import { Link } from "react-router-dom";

const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full  bg-[#EBF7FF] rounded-full h-2 mt-2">
      <div
        className="bg-[#2197ED] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
const Competitions = () => {
  return (
    <div>
      <div className="font-semibold text-[34px] text-[#2B2A2A]  text-center">
        Trading Competitions
      </div>
      <div className="text-[16px] text-[#6C6C6C] text-center">
        Register to show off your skills and win prizes!
      </div>
      <div className="mt-5 flex flex-wrap justify-center gap-5">
        <div className="bg-white rounded-2xl border-[#EAEAEC] border w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <img src={greystar} alt="" />
            <div className="font-semibold text-[20px]">July Competition</div>
          </div>
          <Link to={"/competition-details"}>
            <div
              className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
              style={{
                background:
                  "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
              }}
            >
              <span className="font-semibold text-[24px]">$10K</span> Challenge
            </div>
          </Link>
          <div className="flex justify-between items-center">
            <div className="text-[#FDB528] bg-[#f5e7cd] flex justify-center items-center border font-semibold border-[#FDB528] w-[133px] h-[32px] rounded-2xl">
              REGISTERED
            </div>
            <div className="text-[16px] font-semibold">In Progress</div>
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
          </div>
        </div>
        <div className="bg-white rounded-2xl border-[#EAEAEC] border w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <img src={bluestar} alt="" />
            <div className="font-semibold text-[20px]">
              Champion Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#26C6F9] bg-[#c8ebf6] flex justify-center items-center border font-semibold border-[#26C6F9] w-[133px] h-[32px] rounded-2xl">
              $25 ENTRY FEE
            </div>
            <div className="text-[16px] font-semibold">Upcoming</div>
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $250,000
            worth of prizes!
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
          </div>
        </div>
        <div className="bg-white rounded-2xl border-[#EAEAEC] border w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <img src={greystar} alt="" />
            <div className="font-semibold text-[20px]">
              September Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] bg-[#e3f5d8] flex justify-center items-center font-semibold border border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Open Registration</div>
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
          </div>
        </div>
        <div className="bg-white rounded-2xl border-[#EAEAEC] border w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <img src={greystar} alt="" />
            <div className="font-semibold text-[20px]">October Competition</div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] bg-[#e3f5d8] flex justify-center items-center font-semibold border border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Coming Soon</div>
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
          </div>
        </div>
        <div className="bg-white rounded-2xl border-[#EAEAEC] border w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <img src={greystar} alt="" />
            <div className="font-semibold text-[20px]">
              November Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] bg-[#e3f5d8] flex justify-center items-center border font-semibold border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Coming Soon</div>
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
          </div>
        </div>
        <div className="bg-white rounded-2xl border-[#EAEAEC] border w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <img src={orangestar} alt="" />
            <div className="font-semibold text-[20px]">
              Christmas Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] bg-[#e3f5d8] flex justify-center items-center border font-semibold border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Coming Soon</div>
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-[#EAEAEC]" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competitions;
