import { ChevronDown, CircleArrowLeft } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Sidebar/propmodelLogo.svg";
import {
  Account,
  Affiliate,
  Competition,
  Education,
  Home,
  Leaderboard,
  Mastery,
  Star,
  Support,
  Tools,
} from "../../Assets/Sidebar/SvgComponents";

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [openSubMenus, setOpenSubMenus] = useState({});

  const Menus = [
    { icon: <Home />, title: "Home", path: "/home" },
    {
      heading: "Challenges & Achievements",
      icon: <Star />,
      title: "Challenge",
      submenu: true,
      submenuItems: [
        { title: "New Challenges", path: "/new-challenge" },
        { title: "My Challenge", path: "/my-challenge" },
      ],
      path: "/challenge",
    },
    {
      icon: <Competition />,
      title: "Competitions",
      path: "/competitions",
    },
    {
      icon: <Leaderboard />,
      title: "Leaderboard",
      path: "/leaderboard",
    },
    {
      heading: "Tools & Education",
      icon: <Tools />,
      title: "Tools",
      submenu: true,
      submenuItems: [
        { title: "Submenu 1", path: "/tools/submenu1" },
        { title: "Submenu 2", path: "/tools/submenu2" },
      ],
      path: "/tools",
    },
    {
      icon: <Education />,
      title: "Education",
      submenu: true,
      submenuItems: [
        { title: "Submenu 1", path: "/education/submenu1" },
        { title: "Submenu 2", path: "/education/submenu2" },
      ],
      path: "/education",
    },
    {
      icon: <Mastery />,
      title: "Mastery",
      submenu: true,
      submenuItems: [
        { title: "Submenu 1", path: "/mastery/submenu1" },
        { title: "Submenu 2", path: "/mastery/submenu2" },
      ],
      path: "/mastery",
    },
    {
      heading: "My Account",
      icon: <Affiliate />,
      title: "Affiliate",
      path: "/affiliate",
    },
    {
      icon: <Account />,
      title: "Account",
      submenu: true,
      submenuItems: [
        { title: "Submenu 1", path: "/account/submenu1" },
        { title: "Submenu 2", path: "/account/submenu2" },
      ],
      path: "/account",
    },
    {
      icon: <Support />,
      title: "Support",
      submenu: true,
      submenuItems: [
        { title: "Submenu 1", path: "/support/submenu1" },
        { title: "Submenu 2", path: "/support/submenu2" },
      ],
      path: "/support",
    },
  ];

  const toggleSubMenu = (index) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the submenu for the clicked menu
    }));
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <div
        className={`h-screen p-5 pt-8 ${
          open ? "w-64" : "w-20"
        } duration-300 relative`}
      >
        <CircleArrowLeft
          className={`bg-white text-[#6699FF] text-3xl rounded-full absolute -right-3 top-9 border border-[#6699FF] cursor-pointer ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />

        <div className="inline-flex cursor-pointer">
          <img
            src={logo}
            alt=""
            className={`text-4xl rounded block float-left mr-1 duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />

          <h1
            className={`text-black origin-left font-bold text-2xl duration-300 ${
              !open && "scale-0"
            }`}
          >
            propmodel
          </h1>
        </div>
        <ul className="pt-2">
          {Menus.map((menu, index) => (
            <div key={index}>
              {/* Render Heading or <hr> */}
              {menu.heading &&
                (open ? (
                  <h3 className="text-[#C6C6C6] text-xs uppercase font-semibold mt-4 pl-2">
                    {menu.heading}
                  </h3>
                ) : (
                  <hr className="border-gray-300 my-4" />
                ))}
              {/* Render Menu Item */}
              <Link to={menu.path}></Link>
              <li
                className={`text-gray-300 text-sm flex items-center gap-x-4 justify-between cursor-pointer p-2 rounded-md ${
                  menu.spacing ? "mt-9" : "mt-2"
                } group hover:bg-[#EDF4FF]`}
              >
                <Link
                  to={menu.path}
                  className="flex items-center gap-x-[10px] w-full"
                >
                  <span className="text-2xl block float-left group-hover:text-[#6699FF]">
                    <div className=" menuitem group-hover:text-[#6699FF]">
                      {menu.icon}
                    </div>
                  </span>
                  <span
                    className={`text-base text-[#6C6C6C] font-medium flex-1 group-hover:text-[#6699FF] ${
                      !open && "hidden"
                    }`}
                  >
                    {menu.title}
                  </span>
                </Link>
                {menu.submenu && open && (
                  <ChevronDown
                    className={`transform ${
                      openSubMenus[index] && "rotate-180"
                    } group-hover:text-[#6699FF]`}
                    onClick={() => toggleSubMenu(index)}
                  />
                )}
              </li>
              {menu.submenu && openSubMenus[index] && open && (
                <ul>
                  {menu.submenuItems.map((submenuItem, subIndex) => (
                    <li
                      key={subIndex}
                      className="text-[#6C6C6C] text-sm px-12 flex items-center gap-x-4 cursor-pointer p-2 hover:bg-[#EDF4FF] hover:text-[#6699FF] rounded-md"
                    >
                      <Link to={submenuItem.path}>{submenuItem.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
