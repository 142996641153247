import { MoveUp } from "lucide-react";
import React from "react";

const Profitcards = () => {
  const currentValue = 2000;
  const totalValue = 12000;
  const percentage = (currentValue / totalValue) * 100;

  const cardData = [
    {
      title: "Current Profit",
      value: "$2,000",
      percentage: "14%",
      progressBarColor: "#6699FF",
      bgColor: "#EDF4FF",
      label: "Total Profit",
      total: "$12k",
    },
    {
      title: "Total Equity",
      value: "$10,100k",
      percentage: "14%",
      progressBarColor: "#65D31D",
      bgColor: "#F4FDEE",
      label: "Total Balance",
      total: "$10.3k",
    },
    {
      title: "Daily Drawdown",
      value: "$2,000",
      percentage: "14%",
      progressBarColor: "#FF4D49",
      bgColor: "#FFEAE9",
      label: "T. Daily Drawdown",
      total: "$5k",
    },
    {
      title: "Maximum Drawdown",
      value: "$2,000",
      percentage: "14%",
      progressBarColor: "#FF4D49",
      bgColor: "#FFEAE9",
      label: "T. Max Drawdown",
      total: "$12k",
    },
  ];

  return (
    <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {cardData.map((card, index) => (
        <div
          key={index}
          className="bg-white p-6 rounded-2xl border-[#EAEAEC] border"
        >
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-[#6C6C6C] text-[16px] font-semibold">
                {card.title}
              </h2>
            </div>
          </div>

          <div className="flex items-center gap-x-2">
            <div className="text-[24px] font-semibold">{card.value}</div>
            <div className="bg-[#F4FDEE] w-[59px] h-[32px] rounded-lg text-[#65D31D] font-semibold flex justify-center items-center gap-x-1">
              <MoveUp size={12} />
              {card.percentage}
            </div>
          </div>

          <div className="relative mt-2">
            <div
              className="absolute top-1/2 -translate-y-1/2 left-0 h-2 rounded-full w-full"
              style={{ background: card.bgColor }}
            ></div>
            <div
              className="absolute top-1/2 -translate-y-1/2 left-0 h-2 rounded-full"
              style={{
                width: `${percentage}%`,
                background: card.progressBarColor,
              }}
            ></div>
          </div>

          <div className="flex justify-between items-center mt-4">
            <span className="text-gray-500 text-sm">{card.label}</span>
            <span className="text-gray-500 text-sm">{card.total}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Profitcards;
