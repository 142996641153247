import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Components/Login/Login";
import Signup from "../Components/Signup/Signup";
import Layout from "../Layout/Layout";
import Home from "../Pages/Home/Home";
import NewChallenge from "../Pages/Challenge/Newchallenge/NewChallenge";
import MyChallenge from "../Pages/Challenge/MyChallenge/MyChallenge";
import Competitions from "../Pages/Competitions/Competitions";
import Leaderboard from "../Pages/Leaderboard/Leaderboard";
import CompetitionDetails from "../Pages/Competitions/CompetitionDetails";
import Challenge from "../Pages/Challenge/Challenge";

const PagesRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />

      {/* Protected Routes (with Layout) */}
      <Route
        path="/home"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/challenge"
        element={
          <Layout>
            <Challenge />
          </Layout>
        }
      />
      <Route
        path="/new-challenge"
        element={
          <Layout>
            <NewChallenge />
          </Layout>
        }
      />
      <Route
        path="/my-challenge"
        element={
          <Layout>
            <MyChallenge />
          </Layout>
        }
      />
      <Route
        path="/competitions"
        element={
          <Layout>
            <Competitions />
          </Layout>
        }
      />
      <Route
        path="/competition-details"
        element={
          <Layout>
            <CompetitionDetails />
          </Layout>
        }
      />
      <Route
        path="/leaderboard"
        element={
          <Layout>
            <Leaderboard />
          </Layout>
        }
      />
    </Routes>
  );
};

export default PagesRoutes;
