import React from "react";
import human from "../../Assets/login/human.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Import Autoplay

import "swiper/css";
import "swiper/css/pagination";

const Logincarousel = () => {
  return (
    <div className="relative">
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{
          clickable: true,
          el: ".custom-pagination",
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        slidesPerView="auto"
        loop={true}
        centeredSlides={true}
      >
        <SwiperSlide style={{ width: "500px" }}>
          <div className="bg-[#00000080] p-5 rounded flex flex-col gap-4">
            <div className="text-white font-semibold text-[24px]">
              "Embarking on the journey of investment is not just a financial
              decision; it is a commitment to your future prosperity.”
            </div>
            <div className="flex gap-x-2">
              <div>
                <img
                  src={human}
                  alt="profile"
                  className="w-[44px] h-[44px] rounded-full"
                />
              </div>
              <div className="text-white text-left">
                <div className="font-semibold text-[16px]">Amanda Lexina</div>
                <div className="text-[14px]">Founder of Dropbox</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ width: "500px" }}>
          <div className="bg-[#00000080] p-5 rounded flex flex-col gap-4">
            <div className="text-white font-semibold text-[24px]">
              "Embarking on the journey of investment is not just a financial
              decision; it is a commitment to your future prosperity.”
            </div>
            <div className="flex gap-x-2">
              <div>
                <img
                  src={human}
                  alt="profile"
                  className="w-[44px] h-[44px] rounded-full"
                />
              </div>
              <div className="text-white text-left">
                <div className="font-semibold text-[16px]">Amanda Lexina</div>
                <div className="text-[14px]">Founder of Dropbox</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide style={{ width: "500px" }}>
          <div className="bg-[#00000080] p-5 rounded flex flex-col gap-4">
            <div className="text-white font-semibold text-[24px]">
              "Embarking on the journey of investment is not just a financial
              decision; it is a commitment to your future prosperity.”
            </div>
            <div className="flex gap-x-2">
              <div>
                <img
                  src={human}
                  alt="profile"
                  className="w-[44px] h-[44px] rounded-full"
                />
              </div>
              <div className="text-white text-left">
                <div className="font-semibold text-[16px]">Amanda Lexina</div>
                <div className="text-[14px]">Founder of Dropbox</div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="custom-pagination mt-4"></div>
    </div>
  );
};

export default Logincarousel;
