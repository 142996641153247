import { Route, Routes, Navigate } from "react-router-dom";
import PagesRoutes from "./Routers/PagesRoutes";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Navigate to="/Login" />} />
        <Route path="/*" element={<PagesRoutes />} />
      </Routes>
    </div>
  );
}

export default App;
