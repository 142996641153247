import React from "react";
import more from "../../../Assets/Newchallenge/more.svg";

const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full sm:w-[144px] bg-[#EDF4FF] rounded-full h-2 mt-2">
      <div
        className="bg-[#6699FF] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const GreenProgressBar = ({ progress }) => {
  return (
    <div className="w-full sm:w-[144px] bg-[#F4FDEE] rounded-full h-2 mt-2">
      <div
        className="bg-[#65D31D] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const AdvancedStatistics = () => {
  return (
    <div className="w-full bg-white rounded-2xl border-[#EAEAEC] border p-5">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-lg">Advanced Statistics</div>
        <div>
          <img src={more} alt="" className="cursor-pointer" />
        </div>
      </div>
      <div className="mt-5 grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="border-[#EAEAEC] border rounded-lg p-4">
          <div className="flex justify-between gap-x-2">
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Trades</div>
              <div className="font-semibold">128</div>
            </div>
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C] text-left">Total Lots:</div>
              <div className="font-semibold  text-left">1622</div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 mt-2">
            <div className="flex flex-col gap-y-2">
              <div className="text-[#6C6C6C] w-[144px] ">Profitability</div>
              <div className="font-semibold">85%</div>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="text-[#6C6C6C] w-[144px]">Shorts Won</div>
              <div className="font-semibold">$850.62</div>
            </div>
          </div>
          <BlueProgressBar progress={85} />
        </div>

        <div className="border-[#EAEAEC] border rounded-lg p-4">
          <div className="flex justify-between gap-x-2">
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C] ">Best Trade</div>
              <div className="font-semibold flex gap-x-1">
                $5,028.01{" "}
                <span className="text-[#6C6C6C] font-normal">(July 28)</span>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C]">Worst Trade</div>
              <div className="font-semibold text-[#FF4D49] flex gap-x-1 text-left">
                -$5,028.01{" "}
                <span className="text-[#6C6C6C] font-normal">(July 28)</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 mt-2">
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C]">Longs Won</div>
              <div className="font-semibold flex gap-x-2">
                85% <span className="text-[#6C6C6C] font-normal">(85/100)</span>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C]">Shorts Won</div>
              <div className="font-semibold flex gap-x-2">
                $5,028.01{" "}
                <span className="text-[#6C6C6C] font-normal">(20/28)</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <BlueProgressBar progress={50} />
            <GreenProgressBar progress={65} />
          </div>
        </div>

        <div className="border-[#EAEAEC] border rounded-lg p-4">
          <div className="flex justify-between gap-x-2">
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Most Traded</div>
              <div className="font-semibold">EURUSD</div>
            </div>
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Profit Factor</div>
              <div className="font-semibold">59.03</div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 mt-2">
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Sharpe Ratio:</div>
              <div className="font-semibold">1.01</div>
            </div>
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Risk/Reward Ratio</div>
              <div className="font-semibold">1.14</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedStatistics;
