import React from "react";
import circlechart from "../../../Assets/Newchallenge/circlechart.svg";
const PopularAssets = () => {
  return (
    <div className="w-full bg-white rounded-2xl border-[#EAEAEC] border p-5">
      <div className="font-semibold">Popular Assets</div>
      <div className="text-[#6C6C6C] text-[14px]">Ranked By Total Trades</div>
      <img src={circlechart} alt="" className="" />
    </div>
  );
};

export default PopularAssets;
