import React from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Navbar from "../Components/Navbar/Navbar";

const Layout = ({ children }) => {
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div>
        <Sidebar />
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col">
        {/* Navbar */}
        <div>
          <Navbar />
        </div>

        {/* Main Content */}
        {/* <div
          className="overflow-y-auto bg-[#E8F4FC]"
          style={{
            scrollbarWidth: "thin",
            msOverflowStyle: "none",
          }}
        > */}
        <div
          className="overflow-y-auto bg-[#E6EDFC] h-screen"
          style={{
            scrollbarWidth: "thin",
            msOverflowStyle: "none",
          }}
        >
          <div className="flex justify-center  p-4">
            <div className="w-full">{children}</div>
          </div>
          <div className="flex justify-between p-4">
            <div className="text-[#6C6C6C] lg:text-[16px] text-[12px]">
              All Rights Reserved Propmodel © 2024
            </div>
            <div className="text-[#0377CB] lg:text-[16px] text-[12px] cursor-pointer">
              Need Help?
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
