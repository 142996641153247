import React from "react";
import Profitcards from "./Profitcards";
import Balance from "./Balance";
import PopularAssets from "./PopularAssets";
import MonthlyAnalytics from "./MonthlyAnalytics";
import AdvancedStatistics from "./AdvancedStatistics";
import TradingOverview from "./TradingOverview";
import TradingActivity from "./TradingActivity";
import ChallengeGraph from "./ChallengeGraph";

const Newchallenge = () => {
  return (
    <div className="flex flex-col gap-y-3">
      <Profitcards />
      <Balance />
      <div className="flex lg:justify-between lg:flex-row flex-col  lg:gap-x-4 gap-y-4">
        <PopularAssets />
        <MonthlyAnalytics />
      </div>
      <AdvancedStatistics />
      <div className="flex lg:justify-between lg:flex-row flex-col gap-4">
        <ChallengeGraph />

        <TradingOverview />
      </div>
      <TradingActivity />
    </div>
  );
};

export default Newchallenge;
