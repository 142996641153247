import { MoveLeft, MoveUp } from "lucide-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import myplacement from "../../Assets/Competitions/myplacement.svg";
import prizepool from "../../Assets/Competitions/prizepool.svg";
import freeentry from "../../Assets/Competitions/freeentry.svg";
import timeremain from "../../Assets/Competitions/timeremaining.svg";
import more from "../../Assets/Competitions/more.svg";
import country from "../../Assets/Competitions/country.svg";
const CompetitionDetails = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="flex justify-start items-center gap-x-2 font-semibold text-[14px] cursor-pointer"
      >
        <MoveLeft />
        Back
      </div>
      <div className="text-[34px] font-semibold text-[#2B2A2A] text-center">
        July Competition
      </div>

      <div className=" text-[16px] text-[#6C6C6C] flex justify-center items-center">
        Competition rules are outlined in this article here.
        <span className="text-[#6699FF] font-semibold">Learn more</span>
      </div>

      <div className="mt-5 flex lg:flex-row flex-col gap-4">
        <div className="bg-white w-full md:w-[70%] rounded-2xl flex flex-wrap justify-around p-5 gap-4 md:gap-0">
          <div className="flex space-x-2 w-full md:w-auto ">
            <div>
              <img src={myplacement} alt="My Placement" />
            </div>
            <div>
              <div className="text-[#6C6C6C] text-[16px] font-semibold">
                My Placement
              </div>
              <div className="flex items-center gap-x-2">
                <div className="text-[34px] font-semibold">1st</div>
                <div className="w-[50px] h-[32px] rounded-xl bg-[#F4FDEE] text-[#65D31D] flex justify-center font-semibold items-center">
                  <MoveUp size={13} /> +3
                </div>
              </div>
            </div>
          </div>

          <div className="hidden md:block w-[1px] bg-[#EAEAEC] h-auto mx-4"></div>

          <div className="flex space-x-2 w-full md:w-auto ">
            <div>
              <img src={prizepool} alt="Prize Pool" />
            </div>
            <div>
              <div className="text-[#6C6C6C] text-[16px] font-semibold">
                Prize Pool
              </div>
              <div className="text-[34px] font-semibold">$1,000</div>
            </div>
          </div>

          <div className="hidden md:block w-[1px] bg-[#EAEAEC] h-auto mx-4"></div>

          <div className="flex space-x-2 w-full md:w-auto ">
            <div>
              <img src={freeentry} alt="Entry Fee" />
            </div>
            <div>
              <div className="text-[#6C6C6C] text-[16px] font-semibold">
                Entry Fee
              </div>
              <div className="text-[34px] font-semibold">Free</div>
            </div>
          </div>
        </div>

        <div className="bg-white w-full md:w-[30%] rounded-2xl p-5">
          <div className="flex space-x-2">
            <div>
              <img src={timeremain} alt="Time Remaining" />
            </div>
            <div>
              <div className="text-[#6C6C6C] text-[16px] font-semibold">
                Time Remaining
              </div>
              <div className="text-[34px] font-semibold">
                18 <span className="text-[#6C6C6C] text-[20px]">d</span> 12
                <span className="text-[#6C6C6C] text-[20px]"> h</span> 35
                <span className="text-[#6C6C6C] text-[20px]"> m</span> 51
                <spam className="text-[#6C6C6C] text-[20px]"> s</spam>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-4  bg-white rounded-2xl border-[#EAEAEC] border p-5">
        <div className="flex lg:justify-between lg:flex-row flex-col">
          <div className="font-semibold ">Rangking</div>

          <div className="">
            <img src={more} alt="" />
          </div>
        </div>

        <div className="mt-5 w-full">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-[#6699FF] dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-5 ">
                  RANK
                </th>
                <th scope="col" className="px-6 py-5">
                  User
                </th>
                <th scope="col" className="px-6 py-5">
                  % TILL 1st
                </th>
                <th scope="col" className="px-6 py-5">
                  Gain %
                </th>
                <th scope="col" className="px-6 py-5">
                  Prize
                </th>
                <th scope="col" className="px-6 py-5">
                  COUNTRY
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white  dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-white  dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-white  dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-white  dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] dark:bg-gray-800 ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompetitionDetails;
