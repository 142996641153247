import React from "react";
import barchart from "../../../Assets/Newchallenge/barchart.svg"

const MonthlyAnalytics = () => {
  return (
    <div className="w-full bg-white rounded-2xl border-[#EAEAEC] border p-5">
      <div className="font-semibold">Monthly Analytics</div>
      <div className="text-[#6C6C6C] text-[14px]">Monthly Gain</div>
      <img src={barchart} alt="" className="mt-5"/>
    </div>
  );
};

export default MonthlyAnalytics;
