import React from "react";
import arrowdown from "../../../Assets/Newchallenge/arrow-down.svg";
import more from "../../../Assets/Newchallenge/more.svg";
import tradingoverview from "../../../Assets/Newchallenge/tradingoverview.svg";
const TradingOverview = () => {
  return (
    <div className="w-full bg-white rounded-2xl border-[#EAEAEC] border p-5">
      <div className="flex lg:justify-between lg:flex-row flex-col gap-5">
        <div className="font-semibold ">Trading Overview</div>

        <div className="flex items-center gap-x-5">
          <div className="bg-[#FAFCFD] text-[#2197ED] text-[13px] lg:w-[120px] w-[300px] h-[30px] rounded-xl font-semibold flex justify-center items-center gap-x-1">
            This Week <img src={arrowdown} alt="" />
          </div>
          <img src={more} alt="" />
        </div>
      </div>
      <div className="mt-5">
        <img src={tradingoverview} alt="" className="lg:w-full w-[300px]" />
      </div>
    </div>
  );
};

export default TradingOverview;
